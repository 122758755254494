body {
    // disable selection as on mobile if user taps near a button random text gets selected
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    color: #b3b3b3;
}
a:hover,
a.hover,
a:focus,
a:active,
a.active {
    color: lighten(#b3b3b3, 20);
}

a, .btn {
    touch-action: manipulation;
    -ms-touch-action: manipulation;
}

.btn-default {
    color: #000;
    background-color: #ffffff;
}
.btn-default:hover,
.btn-default.hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #000;
    background-color: darken(#ffffff, 5);
    border-color: darken(#ffffff, 5);
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background: darken(#ffffff, 10);
    border-color: darken(#ffffff, 10);
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled.hover,
.btn-default[disabled].hover,
fieldset[disabled] .btn-default.hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    color: #b7b7b7;
    background-color: #f3f3f3;
    border-color: #f3f3f3;
}
.btn-default .badge {
    color: #ffffff;
    background-color: #fff;
}


.btn-inverse {
    color: #fff;
    background-color: #4b4b4b;
}
.btn-inverse:hover,
.btn-inverse.hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
    color: #fff;
    background-color: darken(#4b4b4b, 10);
    border-color: darken(#4b4b4b, 10);
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
    background: darken(#4b4b4b, 10);
    border-color: darken(#4b4b4b, 10);
}


.palette {
    padding: 15px;
    margin: 0;
    font-size: 14px;
    line-height: 1.214;
    color: #fff;
    text-transform: uppercase;
}
.palette-gray {
    background-color: #e5e5e5;
}
.palette-inverse {
    background-color: #4b4b4b;

    &.tile-block:hover {
        background-color: darken(#4b4b4b, 10);
    }
}
.palette-available {
    color: #000;
    background-color: #ffffff;
    transition: background .25s;

    &.tile-block:hover {
        background-color: lighten(#4b4b4b, 10);
    }
}
.palette-emerald {
    background-color: #2ecc71;
}
.palette-orange {
    background-color: #f39c12;
}

.header {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    height: 63px;
    background: #2B2B2B; // this is for IE9
    background: linear-gradient(
            to bottom,
            #494949 0,
            #434343 12%,
            #393939 25%,
            #323232 39%,
            #2B2B2B 50%,
            #2A2A2A 51%,
            #282828 60%,
            #232323 76%,
            #212121 91%,
            #202020 100%
    );

    @media (min-width: 768px) {
        height: 73px;
    }
}

.brand-logo {
    display: inline-block;
    width: 110px;
    height: auto;

    @media (min-width: 768px) {
        width: 140px;
    }
}

.nav-controls {
    margin: 0;

    [class^="icon-"], [class*=" icon-"] {
        font-size: 1.6em;

        @media (min-width: 768px) {
            margin-right: .5em;
            float: left;
        }

        @media (max-width: 767px) {
            display: block;
            text-align: center;
        }
    }

    &-label {
        @media (max-width: 767px) {
            font-size: .5em;
            position: relative;
            top: -3px;
        }
    }

    .active.btn,
    .active [class^="icon-"], [class*=" icon-"] {
        color: #E74C3C;
    }

    .active [class^="icon-"], [class*=" icon-"],
    .btn:active,
    .btn:hover {
        text-decoration: none;
    }

    body:not(.is_mobile) & {
        .btn:active,
        .btn:hover {
            color: #E74C3C;
            text-decoration: none;
        }
    }

    .btn {
        color: #ffffff;

        @media (max-width: 767px) {
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 5px;
        }

        @media (max-width: 767px) {
            padding-right: 0;
            padding-left: 12px;
            position: relative;
            top: -5px;
        }

        &, span {
            transition: none !important;
        }
    }
}

.tile-list-color {
    & > .btn + .btn {
        margin-top: 0;
    }
}

.tile-block {
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
}
.tile-selected {
    position: absolute;
    top: -2px;
    right: -2px;
    display: block;
    width: 41px;
}

.tile-text {
    position: absolute;
    left: 15%;
    bottom: 1em;
    width: 70%;

    .tile-block-fontStyle_jersey &,
    .tile-block-textLayout &,
    .tile-block-tailType & {
        bottom: 0.3em;
    }

    .font-list &  {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

.tile-preview {
    background-size: contain;
    background-position: center center;
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 1.5em;
}

.tile-preview-color {
    width: 100%;
    padding-bottom: 100%;
}

.tile-block-textLayout,
.tile-block-tailType {
    padding-bottom: 1em;
}
.tile-preview-asset {
    padding-bottom: 30%;
    background-size: 100% auto;
}

.container-main {
    padding-top: 63px;
    width: 100%;
    max-width: 100%;
    position: relative;

    .row .palette-gray {
        min-height: calc(100vh - 63px);

        @media (min-width: 768px) {
            min-height: calc(100vh - 73px);
        }
    }

    @media (min-width: 768px) {
        padding-top: 73px;
        min-height: calc(100% - 73px);
    }
}

.off-canvas {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: z-index linear .25s;

    &.in {
        z-index: 990;
    }
}

.asset-preview {
    top: 73px;
    right: 0;
    bottom: 0;

    @media (max-width: 767px) {
        top: 63px;
        position: absolute;
        left: 0;
        left: 100%\9\0; // hack for IE9
        right: -100%\9\0; // hack for IE9
        padding: 0;
        background: transparentize(#FFFFFF, .05);
        transition: transform linear .25s;
        transform: translate(100%, 0) translateZ(0);

        .in & {
            transform: translate(0, 0) translateZ(0);
            left: 0\9\0; // hack for IE9
            right: 0\9\0; // hack for IE9
        }
    }

    @media (min-width: 768px) {
        position: fixed;
        left: 41.66666667%;
    }

    @media (min-width: 1200px) {
        left: 33.33333333%;
    }

}

.previewArea__adapter,
.previewContainer_canvas {
    width: 100%;
    height: 100%;
}

.asset-preview-placeholder {
    width: 100%;
    height: 100%;

    & > img {
        width: 100%;
        height: auto;
        max-width: 512px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.previewContainer_canvas canvas {
    position: relative;
    display: none;
    margin: 0 auto;
    -webkit-transform-origin: top left;
    transform-origin: top left;

    &.visible {
        display: block;
    }
}

.previewContainer_canvas.invisible {
    display: none
}

.previewContainer_flash {
    position: absolute
}

.previewContainer_flash object {
    display: block;
    width: 100%;
    height: 100%
}

.asset-message {
    position: absolute;
    top: 1em;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 767px) {
        width: 100%;
        padding: 0 15px;

        .informative {
            font-size: 20px;
        }
    }
}
.asset-title {
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 767px) {
        height: 90px;
        width: calc(100% - 140px);
        bottom: 0;
//        background: #2B2B2B;

        .informative {
            font-size: 17px !important;
            line-height: 1.7;
            position: absolute;
            width: 100%;
            padding: 0 10px;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
        }
    }
}

.uploader {
    iframe {
        position: absolute;
        left: -10000px;
    }
}

.uploader-input {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0.01;
}

.uploader-input-ghost {
    background: transparent;
}

.uploader .uploader-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none !important;
    background-color: #ffffff;
    color: #000;
}

.uploader-preview-container {
    margin-top: 1em;
    background: url(/images/transp_bg.png);
//    border: 2px solid #BDC3C7;
//    border-radius: 6px;

    .uploader-preview {
        padding-bottom: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

@keyframes progress-bar-stripes {
    from { background-position: 84px 0 }
    to   { background-position: 0 0 }
}

.uploader-progress-bar {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    animation: progress-bar-stripes 0.8s linear infinite;
    background: linear-gradient(
            -45deg,
            rgba(189, 195, 199, .50) 25%,
            transparent 25%,
            transparent 50%,
            rgba(189, 195, 199, .50) 50%,
            rgba(189, 195, 199, .50) 75%,
            transparent 75%,
            transparent
    );
    background-color: #ffffff;
    background-size: 84px 84px;
    pointer-events: none;
}

.blur {
    filter: blur(2px);
}
.grayscale {
    filter: grayscale(100%);
}

.loading {
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 200;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        color: #000;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        animation: rotate 1s linear infinite;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiPjxwYXRoIGQ9Ik01MTIgMEMyMzMuMjUyIDAgNi41NDIgMjIyLjc2Mi4xNTIgNDk5Ljk3NCA2LjA3MiAyNTguMTEgMTg5Ljk4NCA2NCA0MTYgNjRjMjI5Ljc1IDAgNDE2IDIwMC41NzYgNDE2IDQ0OCAwIDUzLjAyIDQyLjk4IDk2IDk2IDk2czk2LTQyLjk4IDk2LTk2QzEwMjQgMjI5LjIzIDc5NC43NyAwIDUxMiAwem0wIDEwMjRjMjc4Ljc0OCAwIDUwNS40NTgtMjIyLjc2MiA1MTEuODQ4LTQ5OS45NzRDMTAxNy45MjggNzY1Ljg5IDgzNC4wMTYgOTYwIDYwOCA5NjBjLTIyOS43NSAwLTQxNi0yMDAuNTc2LTQxNi00NDggMC01My4wMi00Mi45OC05Ni05Ni05NlMwIDQ1OC45OCAwIDUxMmMwIDI4Mi43NyAyMjkuMjMgNTEyIDUxMiA1MTJ6Ii8+PC9zdmc+);
        animation-delay: 100ms;
        cursor: wait;
    }

    &-inline {
        width: 14px;
        height: 13px;
        display: inline-block;
        margin-right: 5px !important;

        &:before {
            background-position: center;
            background-size: 16px 16px;
        }
    }
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.inline-block {
    display: inline-block;
}

.modal-backdrop {
    position: fixed;
}

.modal-content {

    .btn-print[disabled] {
        color: #393939;
    }
}

.design-summary {
    th {
        width: 30%;
    }

    .roster {
        th {
            width: auto;
        }
    }

    tr:last-of-type td {
        border-bottom: 2px solid #EEF0F0;
    }

    & > div, & > p {
        page-break-inside: avoid;
    }
}
.print-view table td:last-child {
    border-bottom: 2px solid #EEF0F0;
}

.main-nav {
    padding-top: 15px;
    padding-bottom: 25px;
}

.marker {
    position: absolute;
    z-index: 0;
}

.marker-color {
    width: 30px;
    height: 50px;
    top: 0;
    left: 0;
    overflow: hidden;

    .marker-inner {
        display: block;
        width: 100%;
        height: 100%;
        border-right: 1px solid darken(#BDC3C7, 5);
    }

    &.marker-circle {
        width: 50px;
        height: 50px;

        .marker-inner {
            transform: scale(.5);
            border-radius: 50% 50%;
            border: 4px solid white;
        }
    }

    &.marker-triangle {
        top: 50%;
        transform: translate(0, -50%);

        .marker-inner {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}

.marker-visited {
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);

    &-none {
        display: none;
    }

    &-partial {
        display: block;
        color: gray;
    }

    &-full {
        display: block;
    }

}

.menu {
    &-gap-bottom {
        margin-bottom: 15px;
    }

    .btn {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .btn-group-vertical:not(.tile-list-color) > .btn {
        margin-top: 0;
        border-bottom: 1px solid darken(#BDC3C7, 5) !important; // important avoids animation from white to gray color

        &:last-of-type {
            border-bottom: 0;
        }
    }

    .btn-group-vertical > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 6px;
    }

    .btn-group-justified > .btn {
        border-right: 1px solid darken(#BDC3C7, 5) !important;

        &:last-of-type {
            border-right: 0;
        }
    }

    @media (max-width: 767px) {
        .btn { // on mobile all buttons must be .btn-hg
            padding: 13px 20px;
            font-size: 22px;
            line-height: 1.227;
            border-radius: 6px;
            overflow: inherit;
            white-space: normal;
            text-overflow: inherit;
        }
        .input-group>.form-control,
        .input-group>.input-group-addon,
        .input-group>.input-group-btn>.btn,
        .input-group>.select2-search input[type=text] {
            height: 53px;
            padding: 10px 16px;
            font-size: 22px;
            line-height: 1.318;
            border-radius: 6px;
        }
        .input-group-btn:last-child>.btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    @media (max-width: 480px) {
        .btn {
            font-size: 19px;
        }
    }

}

.breadcrumb {
    font-size: 17px;
    line-height: 1.471;

    &_root-menu-icon {
        padding-right: 0.5em;
    }

    li:first-of-type a {
        position: relative;
        margin: -10px;
        padding: 10px;
    }

    @media (max-width: 767px) {
        padding: 13px 20px;
        font-size: 22px;
        line-height: 1.227;
    }
}

.roster-edit {

    & .row {
        padding-bottom: 7.5px;
        margin: 0;

        & > div:not(:last-of-type) {
            padding-right: 0;
        }
    }

    .col-roster-size,
    .col-roster-qty,
    .col-roster-nr,
    .col-roster-name,
    .col-roster-remove {
        float: left;
        padding: 0;
        margin-left: -2px;
    }

    .col-roster-size {
        width: 70px;

        & select {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            -webkit-appearance: none;
        }
    }
    .col-roster-qty,
    .col-roster-nr {
        width: 50px;
        word-break: break-all;

        & input {
            border-radius: 0;
        }
    }
    .col-roster-name {
        width: calc(100% - 190px);
        white-space: nowrap;

        & input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .col-roster-remove {
        width: 30px;
        padding: 0;
        position: relative;
        top: 2px;
    }
}

.break-after {
    page-break-after: always;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .ng-hide {
    display: none !important;
}

.informative {
    font-size: 15px;
    line-height: 21px;
    margin-top: 5px;

    // enable text selection
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

// fix IE9 problem with .off state
.bootstrap-switch-container {
    position: relative;
}

.colors {
    .color-group + .color-group {
        margin-top: 15px;
    }
}

.view-switcher {
    background-color: rgba(255,255,255,0);
    position: absolute;
    min-width: 70px;

    @media (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
    }

    @media (max-width: 767px) {
        bottom: 0;
        border-radius: 0;
        padding: 0;
    }
    &.btn {
        color: #000000;
    }
    &.btn:hover, &.btn:focus {
        color: #e11f29;
    }

    &-left {
        left: 0;

        @media (max-width: 767px) {
            left: 0;
        }
    }

    &-right {
        right: 15px;

        @media (max-width: 767px) {
            right: 0;
        }
    }

    .icon {
        font-size: 4em;
    }
}

.modal-summary {

    .share-buttons {
        text-align: left;

        @media (max-width: 767px) {
            .btn-block {
                margin-bottom: 0;
            }
        }

        @media (min-width: 768px) {
            label {
                float: left;
                margin-right: 1em;
            }

            .btn-block {
                display: inline-block;
                width: auto;
                float: left;
                margin-top: 0;
            }
        }
    }

    @media (max-width: 767px) {
        .btn-print {
            display: block;
            width: 100%;
        }

        .actions-label {
            text-align: left;
            margin-top: 15px;
        }
    }

}

.u {
    &-mt-1em {
        margin-top: 1em;
    }
}
