.tiles__item_fontStyle_jersey,
.tiles__item_fontStyle_gloves {
    background-size: 100% auto;
}
.tiles__item_fontStyle_jersey { background-image: url('/assets/fontStyles-jerseys.svg'); }
.tiles__item_fontStyle_gloves { background-image: url('/assets/fontStyles-gloves.svg'); }

.tiles__item_fontStyle_jersey_varsity { background-position: 0 0; }
.tiles__item_fontStyle_jersey_tiffany { background-position: 0 10%; }
.tiles__item_fontStyle_jersey_script { background-position: 0 20%; }
.tiles__item_fontStyle_jersey_rockwell { background-position: 0 30%; }
.tiles__item_fontStyle_jersey_proBlock { background-position: 0 40%; }
.tiles__item_fontStyle_jersey_pirate { background-position: 0 50%; }
.tiles__item_fontStyle_jersey_oldEnglish { background-position: 0 60%; }
.tiles__item_fontStyle_jersey_newAthletic { background-position: 0 70%; }
.tiles__item_fontStyle_jersey_hobo { background-position: 0 80%; }
.tiles__item_fontStyle_jersey_freshman { background-position: 0 90%; }
.tiles__item_fontStyle_jersey_brush { background-position: 0 100%; }

.tiles__item_fontStyle_gloves_block { background-position: 0 0; }
.tiles__item_fontStyle_gloves_script { background-position: 0 100%; }

.tiles__item_textLayout {
    background-image: url('/assets/textLayouts.svg');
    background-size: 100% auto;
}
.tiles__item_textLayout_verticalArch { background-position: 0 0; }
.tiles__item_textLayout_sweep { background-position: 0 14%; }
.tiles__item_textLayout_straight { background-position: 0 28%; }
.tiles__item_textLayout_reversedBookends { background-position: 0 43%; }
.tiles__item_textLayout_bridgeBookends { background-position: 0 57%; }
.tiles__item_textLayout_bridge { background-position: 0 71.5%; }
.tiles__item_textLayout_archedBookends { background-position: 0 85%; }
.tiles__item_textLayout_arched { background-position: 0 100%; }

.tiles__item_tailType {
    background-image: url('/assets/tailingTypes.svg');
    background-size: 100% auto;
}
.tiles__item_tailType_jet { background-position: 0 0; }
.tiles__item_tailType_standard { background-position: 0 33%; }
.tiles__item_tailType_stanford { background-position: 0 66%; }
.tiles__item_tailType_sweep { background-position: 0 100%; }
