.tile-color-none {
  color: #000 !important;
  background-color: null;
}

.tile-color-white {
  color: #000 !important;
  background-color: #f0f0f0;
}

.tile-color-lightGrey {
  color: #000 !important;
  background-color: #bec0c2;
}

.tile-color-grey {
  color: #fff !important;
  background-color: #455349;
}

.tile-color-black {
  color: #fff !important;
  background-color: #231f20;
}

.tile-color-forest {
  color: #fff !important;
  background-color: #004a18;
}

.tile-color-kelly {
  color: #fff !important;
  background-color: #00a547;
}

.tile-color-limeGreen {
  color: #fff !important;
  background-color: #7dc242;
}

.tile-color-hopkinsBlue {
  color: #fff !important;
  background-color: #71adcd;
}

.tile-color-divaBlue {
  color: #fff !important;
  background-color: #00aec2;
}

.tile-color-royal {
  color: #fff !important;
  background-color: #006296;
}

.tile-color-navy {
  color: #fff !important;
  background-color: #000538;
}

.tile-color-purple {
  color: #fff !important;
  background-color: #363e99;
}

.tile-color-texasOrange {
  color: #fff !important;
  background-color: #974307;
}

.tile-color-vegas {
  color: #000 !important;
  background-color: #d6af58;
}

.tile-color-athleticGold {
  color: #000 !important;
  background-color: #fec026;
}

.tile-color-higlighterYellow {
  color: #000 !important;
  background-color: #eee80a;
}

.tile-color-maize {
  color: #000 !important;
  background-color: #ffd520;
}

.tile-color-tennesseeOrange {
  color: #fff !important;
  background-color: #f89728;
}

.tile-color-orange {
  color: #fff !important;
  background-color: #f15f22;
}

.tile-color-shockingPink {
  color: #fff !important;
  background-color: #de3d96;
}

.tile-color-pink {
  color: #fff !important;
  background-color: #ed829f;
}

.tile-color-red {
  color: #fff !important;
  background-color: #b51834;
}

.tile-color-cardinal {
  color: #fff !important;
  background-color: #6b0004;
}

.tile-color-maroon {
  color: #fff !important;
  background-color: #450000;
}

.tile-color-brown {
  color: #fff !important;
  background-color: #430e00;
}

.tile-color-none {
  color: #000 !important;
  background-color: transparent !important;
  background-image: url(/images/transp_bg.png) !important;
}

.tile-color-digitalSnow {
  color: #000 !important;
  background-image: url(/assets/patterns/digitalSnow.png) !important;
}

.tile-color-digitalSand {
  color: #fff !important;
  background-image: url(/assets/patterns/digitalSand.png) !important;
}

.tile-color-digitalTan {
  color: #fff !important;
  background-image: url(/assets/patterns/digitalTan.png) !important;
}

.tile-color-digitalDark {
  color: #fff !important;
  background-image: url(/assets/patterns/digitalDark.png) !important;
}

.tile-color-digitalSnow,
.tile-color-digitalSand,
.tile-color-digitalTan,
.tile-color-digitalDark {
  background-position: center center;
  background-size: cover;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  color: #b3b3b3;
}

a:hover,
a.hover,
a:focus,
a:active,
a.active {
  color: #e6e6e6;
}

a,
.btn {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

.btn-default {
  color: #000;
  background-color: #ffffff;
}

.btn-default:hover,
.btn-default.hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #000;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled.hover,
.btn-default[disabled].hover,
fieldset[disabled] .btn-default.hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  color: #b7b7b7;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.btn-default .badge {
  color: #ffffff;
  background-color: #fff;
}

.btn-inverse {
  color: #fff;
  background-color: #4b4b4b;
}

.btn-inverse:hover,
.btn-inverse.hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #fff;
  background-color: #323232;
  border-color: #323232;
}

.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background: #323232;
  border-color: #323232;
}

.palette {
  padding: 15px;
  margin: 0;
  font-size: 14px;
  line-height: 1.214;
  color: #fff;
  text-transform: uppercase;
}

.palette-gray {
  background-color: #e5e5e5;
}

.palette-inverse {
  background-color: #4b4b4b;
}

.palette-inverse.tile-block:hover {
  background-color: #323232;
}

.palette-available {
  color: #000;
  background-color: #ffffff;
  transition: background .25s;
}

.palette-available.tile-block:hover {
  background-color: #656565;
}

.palette-emerald {
  background-color: #2ecc71;
}

.palette-orange {
  background-color: #f39c12;
}

.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  height: 63px;
  background: #2B2B2B;
  background: linear-gradient(to bottom, #494949 0, #434343 12%, #393939 25%, #323232 39%, #2B2B2B 50%, #2A2A2A 51%, #282828 60%, #232323 76%, #212121 91%, #202020 100%);
}

@media (min-width: 768px) {
  .header {
    height: 73px;
  }
}

.brand-logo {
  display: inline-block;
  width: 110px;
  height: auto;
}

@media (min-width: 768px) {
  .brand-logo {
    width: 140px;
  }
}

.nav-controls {
  margin: 0;
}

.nav-controls [class^="icon-"],
.nav-controls [class*=" icon-"] {
  font-size: 1.6em;
}

@media (min-width: 768px) {
  .nav-controls [class^="icon-"],
  .nav-controls [class*=" icon-"] {
    margin-right: .5em;
    float: left;
  }
}

@media (max-width: 767px) {
  .nav-controls [class^="icon-"],
  .nav-controls [class*=" icon-"] {
    display: block;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .nav-controls-label {
    font-size: .5em;
    position: relative;
    top: -3px;
  }
}

.nav-controls .active.btn,
.nav-controls .active [class^="icon-"],
.nav-controls [class*=" icon-"] {
  color: #E74C3C;
}

.nav-controls .active [class^="icon-"],
.nav-controls [class*=" icon-"],
.nav-controls .btn:active,
.nav-controls .btn:hover {
  text-decoration: none;
}

body:not(.is_mobile) .nav-controls .btn:active,
body:not(.is_mobile) .nav-controls .btn:hover {
  color: #E74C3C;
  text-decoration: none;
}

.nav-controls .btn {
  color: #ffffff;
}

@media (max-width: 767px) {
  .nav-controls .btn {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 5px;
  }
}

@media (max-width: 767px) {
  .nav-controls .btn {
    padding-right: 0;
    padding-left: 12px;
    position: relative;
    top: -5px;
  }
}

.nav-controls .btn,
.nav-controls .btn span {
  transition: none !important;
}

.tile-list-color > .btn + .btn {
  margin-top: 0;
}

.tile-block {
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}

.tile-selected {
  position: absolute;
  top: -2px;
  right: -2px;
  display: block;
  width: 41px;
}

.tile-text {
  position: absolute;
  left: 15%;
  bottom: 1em;
  width: 70%;
}

.tile-block-fontStyle_jersey .tile-text,
.tile-block-textLayout .tile-text,
.tile-block-tailType .tile-text {
  bottom: 0.3em;
}

.font-list .tile-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tile-preview {
  background-size: contain;
  background-position: center center;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 1.5em;
}

.tile-preview-color {
  width: 100%;
  padding-bottom: 100%;
}

.tile-block-textLayout,
.tile-block-tailType {
  padding-bottom: 1em;
}

.tile-preview-asset {
  padding-bottom: 30%;
  background-size: 100% auto;
}

.container-main {
  padding-top: 63px;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.container-main .row .palette-gray {
  min-height: calc(100vh - 63px);
}

@media (min-width: 768px) {
  .container-main .row .palette-gray {
    min-height: calc(100vh - 73px);
  }
}

@media (min-width: 768px) {
  .container-main {
    padding-top: 73px;
    min-height: calc(100% - 73px);
  }
}

.off-canvas {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: z-index linear .25s;
}

.off-canvas.in {
  z-index: 990;
}

.asset-preview {
  top: 73px;
  right: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .asset-preview {
    top: 63px;
    position: absolute;
    left: 0;
    left: 100%\9\0;
    right: -100%\9\0;
    padding: 0;
    background: rgba(255, 255, 255, 0.95);
    transition: transform linear .25s;
    transform: translate(100%, 0) translateZ(0);
  }

  .in .asset-preview {
    transform: translate(0, 0) translateZ(0);
    left: 0\9\0;
    right: 0\9\0;
  }
}

@media (min-width: 768px) {
  .asset-preview {
    position: fixed;
    left: 41.66666667%;
  }
}

@media (min-width: 1200px) {
  .asset-preview {
    left: 33.33333333%;
  }
}

.previewArea__adapter,
.previewContainer_canvas {
  width: 100%;
  height: 100%;
}

.asset-preview-placeholder {
  width: 100%;
  height: 100%;
}

.asset-preview-placeholder > img {
  width: 100%;
  height: auto;
  max-width: 512px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.previewContainer_canvas canvas {
  position: relative;
  display: none;
  margin: 0 auto;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.previewContainer_canvas canvas.visible {
  display: block;
}

.previewContainer_canvas.invisible {
  display: none;
}

.previewContainer_flash {
  position: absolute;
}

.previewContainer_flash object {
  display: block;
  width: 100%;
  height: 100%;
}

.asset-message {
  position: absolute;
  top: 1em;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 767px) {
  .asset-message {
    width: 100%;
    padding: 0 15px;
  }

  .asset-message .informative {
    font-size: 20px;
  }
}

.asset-title {
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 767px) {
  .asset-title {
    height: 90px;
    width: calc(100% - 140px);
    bottom: 0;
  }

  .asset-title .informative {
    font-size: 17px !important;
    line-height: 1.7;
    position: absolute;
    width: 100%;
    padding: 0 10px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}

.uploader iframe {
  position: absolute;
  left: -10000px;
}

.uploader-input {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0.01;
}

.uploader-input-ghost {
  background: transparent;
}

.uploader .uploader-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none !important;
  background-color: #ffffff;
  color: #000;
}

.uploader-preview-container {
  margin-top: 1em;
  background: url(/images/transp_bg.png);
}

.uploader-preview-container .uploader-preview {
  padding-bottom: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 84px 0;
  }

  to {
    background-position: 0 0;
  }
}

.uploader-progress-bar {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  animation: progress-bar-stripes 0.8s linear infinite;
  background: linear-gradient(-45deg, rgba(189, 195, 199, 0.5) 25%, transparent 25%, transparent 50%, rgba(189, 195, 199, 0.5) 50%, rgba(189, 195, 199, 0.5) 75%, transparent 75%, transparent);
  background-color: #ffffff;
  background-size: 84px 84px;
  pointer-events: none;
}

.blur {
  filter: blur(2px);
}

.grayscale {
  filter: grayscale(100%);
}

.loading:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 200;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  color: #000;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  animation: rotate 1s linear infinite;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiPjxwYXRoIGQ9Ik01MTIgMEMyMzMuMjUyIDAgNi41NDIgMjIyLjc2Mi4xNTIgNDk5Ljk3NCA2LjA3MiAyNTguMTEgMTg5Ljk4NCA2NCA0MTYgNjRjMjI5Ljc1IDAgNDE2IDIwMC41NzYgNDE2IDQ0OCAwIDUzLjAyIDQyLjk4IDk2IDk2IDk2czk2LTQyLjk4IDk2LTk2QzEwMjQgMjI5LjIzIDc5NC43NyAwIDUxMiAwem0wIDEwMjRjMjc4Ljc0OCAwIDUwNS40NTgtMjIyLjc2MiA1MTEuODQ4LTQ5OS45NzRDMTAxNy45MjggNzY1Ljg5IDgzNC4wMTYgOTYwIDYwOCA5NjBjLTIyOS43NSAwLTQxNi0yMDAuNTc2LTQxNi00NDggMC01My4wMi00Mi45OC05Ni05Ni05NlMwIDQ1OC45OCAwIDUxMmMwIDI4Mi43NyAyMjkuMjMgNTEyIDUxMiA1MTJ6Ii8+PC9zdmc+);
  animation-delay: 100ms;
  cursor: wait;
}

.loading-inline {
  width: 14px;
  height: 13px;
  display: inline-block;
  margin-right: 5px !important;
}

.loading-inline:before {
  background-position: center;
  background-size: 16px 16px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inline-block {
  display: inline-block;
}

.modal-backdrop {
  position: fixed;
}

.modal-content .btn-print[disabled] {
  color: #393939;
}

.design-summary th {
  width: 30%;
}

.design-summary .roster th {
  width: auto;
}

.design-summary tr:last-of-type td {
  border-bottom: 2px solid #EEF0F0;
}

.design-summary > div,
.design-summary > p {
  page-break-inside: avoid;
}

.print-view table td:last-child {
  border-bottom: 2px solid #EEF0F0;
}

.main-nav {
  padding-top: 15px;
  padding-bottom: 25px;
}

.marker {
  position: absolute;
  z-index: 0;
}

.marker-color {
  width: 30px;
  height: 50px;
  top: 0;
  left: 0;
  overflow: hidden;
}

.marker-color .marker-inner {
  display: block;
  width: 100%;
  height: 100%;
  border-right: 1px solid #afb6bb;
}

.marker-color.marker-circle {
  width: 50px;
  height: 50px;
}

.marker-color.marker-circle .marker-inner {
  transform: scale(0.5);
  border-radius: 50% 50%;
  border: 4px solid white;
}

.marker-color.marker-triangle {
  top: 50%;
  transform: translate(0, -50%);
}

.marker-color.marker-triangle .marker-inner {
  transform: translate(-50%, -50%) rotate(45deg);
}

.marker-visited {
  top: 50%;
  right: 16px;
  transform: translate(0, -50%);
}

.marker-visited-none {
  display: none;
}

.marker-visited-partial {
  display: block;
  color: gray;
}

.marker-visited-full {
  display: block;
}

.menu-gap-bottom {
  margin-bottom: 15px;
}

.menu .btn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menu .btn-group-vertical:not(.tile-list-color) > .btn {
  margin-top: 0;
  border-bottom: 1px solid #afb6bb !important;
}

.menu .btn-group-vertical:not(.tile-list-color) > .btn:last-of-type {
  border-bottom: 0;
}

.menu .btn-group-vertical > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 6px;
}

.menu .btn-group-justified > .btn {
  border-right: 1px solid #afb6bb !important;
}

.menu .btn-group-justified > .btn:last-of-type {
  border-right: 0;
}

@media (max-width: 767px) {
  .menu .btn {
    padding: 13px 20px;
    font-size: 22px;
    line-height: 1.227;
    border-radius: 6px;
    overflow: inherit;
    white-space: normal;
    text-overflow: inherit;
  }

  .menu .input-group > .form-control,
  .menu .input-group > .input-group-addon,
  .menu .input-group > .input-group-btn > .btn,
  .menu .input-group > .select2-search input[type=text] {
    height: 53px;
    padding: 10px 16px;
    font-size: 22px;
    line-height: 1.318;
    border-radius: 6px;
  }

  .menu .input-group-btn:last-child > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (max-width: 480px) {
  .menu .btn {
    font-size: 19px;
  }
}

.breadcrumb {
  font-size: 17px;
  line-height: 1.471;
}

.breadcrumb_root-menu-icon {
  padding-right: 0.5em;
}

.breadcrumb li:first-of-type a {
  position: relative;
  margin: -10px;
  padding: 10px;
}

@media (max-width: 767px) {
  .breadcrumb {
    padding: 13px 20px;
    font-size: 22px;
    line-height: 1.227;
  }
}

.roster-edit .row {
  padding-bottom: 7.5px;
  margin: 0;
}

.roster-edit .row > div:not(:last-of-type) {
  padding-right: 0;
}

.roster-edit .col-roster-size,
.roster-edit .col-roster-qty,
.roster-edit .col-roster-nr,
.roster-edit .col-roster-name,
.roster-edit .col-roster-remove {
  float: left;
  padding: 0;
  margin-left: -2px;
}

.roster-edit .col-roster-size {
  width: 70px;
}

.roster-edit .col-roster-size select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  -webkit-appearance: none;
}

.roster-edit .col-roster-qty,
.roster-edit .col-roster-nr {
  width: 50px;
  word-break: break-all;
}

.roster-edit .col-roster-qty input,
.roster-edit .col-roster-nr input {
  border-radius: 0;
}

.roster-edit .col-roster-name {
  width: calc(100% - 190px);
  white-space: nowrap;
}

.roster-edit .col-roster-name input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.roster-edit .col-roster-remove {
  width: 30px;
  padding: 0;
  position: relative;
  top: 2px;
}

.break-after {
  page-break-after: always;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide {
  display: none !important;
}

.informative {
  font-size: 15px;
  line-height: 21px;
  margin-top: 5px;
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.bootstrap-switch-container {
  position: relative;
}

.colors .color-group + .color-group {
  margin-top: 15px;
}

.view-switcher {
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  min-width: 70px;
}

@media (min-width: 768px) {
  .view-switcher {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 767px) {
  .view-switcher {
    bottom: 0;
    border-radius: 0;
    padding: 0;
  }
}

.view-switcher.btn {
  color: #000000;
}

.view-switcher.btn:hover,
.view-switcher.btn:focus {
  color: #e11f29;
}

.view-switcher-left {
  left: 0;
}

@media (max-width: 767px) {
  .view-switcher-left {
    left: 0;
  }
}

.view-switcher-right {
  right: 15px;
}

@media (max-width: 767px) {
  .view-switcher-right {
    right: 0;
  }
}

.view-switcher .icon {
  font-size: 4em;
}

.modal-summary .share-buttons {
  text-align: left;
}

@media (max-width: 767px) {
  .modal-summary .share-buttons .btn-block {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .modal-summary .share-buttons label {
    float: left;
    margin-right: 1em;
  }

  .modal-summary .share-buttons .btn-block {
    display: inline-block;
    width: auto;
    float: left;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .modal-summary .btn-print {
    display: block;
    width: 100%;
  }

  .modal-summary .actions-label {
    text-align: left;
    margin-top: 15px;
  }
}

.u-mt-1em {
  margin-top: 1em;
}

.tiles__item_fontStyle_jersey,
.tiles__item_fontStyle_gloves {
  background-size: 100% auto;
}

.tiles__item_fontStyle_jersey {
  background-image: url("/assets/fontStyles-jerseys.svg");
}

.tiles__item_fontStyle_gloves {
  background-image: url("/assets/fontStyles-gloves.svg");
}

.tiles__item_fontStyle_jersey_varsity {
  background-position: 0 0;
}

.tiles__item_fontStyle_jersey_tiffany {
  background-position: 0 10%;
}

.tiles__item_fontStyle_jersey_script {
  background-position: 0 20%;
}

.tiles__item_fontStyle_jersey_rockwell {
  background-position: 0 30%;
}

.tiles__item_fontStyle_jersey_proBlock {
  background-position: 0 40%;
}

.tiles__item_fontStyle_jersey_pirate {
  background-position: 0 50%;
}

.tiles__item_fontStyle_jersey_oldEnglish {
  background-position: 0 60%;
}

.tiles__item_fontStyle_jersey_newAthletic {
  background-position: 0 70%;
}

.tiles__item_fontStyle_jersey_hobo {
  background-position: 0 80%;
}

.tiles__item_fontStyle_jersey_freshman {
  background-position: 0 90%;
}

.tiles__item_fontStyle_jersey_brush {
  background-position: 0 100%;
}

.tiles__item_fontStyle_gloves_block {
  background-position: 0 0;
}

.tiles__item_fontStyle_gloves_script {
  background-position: 0 100%;
}

.tiles__item_textLayout {
  background-image: url("/assets/textLayouts.svg");
  background-size: 100% auto;
}

.tiles__item_textLayout_verticalArch {
  background-position: 0 0;
}

.tiles__item_textLayout_sweep {
  background-position: 0 14%;
}

.tiles__item_textLayout_straight {
  background-position: 0 28%;
}

.tiles__item_textLayout_reversedBookends {
  background-position: 0 43%;
}

.tiles__item_textLayout_bridgeBookends {
  background-position: 0 57%;
}

.tiles__item_textLayout_bridge {
  background-position: 0 71.5%;
}

.tiles__item_textLayout_archedBookends {
  background-position: 0 85%;
}

.tiles__item_textLayout_arched {
  background-position: 0 100%;
}

.tiles__item_tailType {
  background-image: url("/assets/tailingTypes.svg");
  background-size: 100% auto;
}

.tiles__item_tailType_jet {
  background-position: 0 0;
}

.tiles__item_tailType_standard {
  background-position: 0 33%;
}

.tiles__item_tailType_stanford {
  background-position: 0 66%;
}

.tiles__item_tailType_sweep {
  background-position: 0 100%;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/fa/icomoon.eot?xjpork");
  src: url("../fonts/fa/icomoon.eot?#iefixxjpork") format("embedded-opentype"), url("../fonts/fa/icomoon.ttf?xjpork") format("truetype"), url("../fonts/fa/icomoon.woff?xjpork") format("woff"), url("../fonts/fa/icomoon.svg?xjpork#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-image:before {
  content: "\e600";
}

.icon-edit:before {
  content: "\e601";
}

.icon-paper-plane:before {
  content: "\e602";
}

.icon-share-square:before {
  content: "\e603";
}