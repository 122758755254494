.tile-color-none {
	color: #000 !important;
	background-color: null
}
.tile-color-white {
	color: #000 !important;
	background-color: #f0f0f0
}
.tile-color-lightGrey {
	color: #000 !important;
	background-color: #bec0c2
}
.tile-color-grey {
	color: #fff !important;
	background-color: #455349
}
.tile-color-black {
	color: #fff !important;
	background-color: #231f20
}
.tile-color-forest {
	color: #fff !important;
	background-color: #004a18
}
.tile-color-kelly {
	color: #fff !important;
	background-color: #00a547
}
.tile-color-limeGreen {
	color: #fff !important;
	background-color: #7dc242
}
.tile-color-hopkinsBlue {
	color: #fff !important;
	background-color: #71adcd
}
.tile-color-divaBlue {
	color: #fff !important;
	background-color: #00aec2
}
.tile-color-royal {
	color: #fff !important;
	background-color: #006296
}
.tile-color-navy {
	color: #fff !important;
	background-color: #000538
}
.tile-color-purple {
	color: #fff !important;
	background-color: #363e99
}
.tile-color-texasOrange {
	color: #fff !important;
	background-color: #974307
}
.tile-color-vegas {
	color: #000 !important;
	background-color: #d6af58
}
.tile-color-athleticGold {
	color: #000 !important;
	background-color: #fec026
}
.tile-color-higlighterYellow {
	color: #000 !important;
	background-color: #eee80a
}
.tile-color-maize {
	color: #000 !important;
	background-color: #ffd520
}
.tile-color-tennesseeOrange {
	color: #fff !important;
	background-color: #f89728
}
.tile-color-orange {
	color: #fff !important;
	background-color: #f15f22
}
.tile-color-shockingPink {
	color: #fff !important;
	background-color: #de3d96
}
.tile-color-pink {
	color: #fff !important;
	background-color: #ed829f
}
.tile-color-red {
	color: #fff !important;
	background-color: #b51834
}
.tile-color-cardinal {
	color: #fff !important;
	background-color: #6b0004
}
.tile-color-maroon {
	color: #fff !important;
	background-color: #450000
}
.tile-color-brown {
	color: #fff !important;
	background-color: #430e00
}
.tile-color-none {
	color: #000 !important;
	background-color: transparent !important;
	background-image: url(/images/transp_bg.png) !important
}
.tile-color-digitalSnow {
	color: #000 !important;
	background-image: url(/assets/patterns/digitalSnow.png) !important
}
.tile-color-digitalSand {
	color: #fff !important;
	background-image: url(/assets/patterns/digitalSand.png) !important
}
.tile-color-digitalTan {
	color: #fff !important;
	background-image: url(/assets/patterns/digitalTan.png) !important
}
.tile-color-digitalDark{
	color: #fff !important;
	background-image: url(/assets/patterns/digitalDark.png) !important
}
.tile-color-digitalSnow,
.tile-color-digitalSand,
.tile-color-digitalTan,
.tile-color-digitalDark {
	background-position: center center;
	background-size: cover
}