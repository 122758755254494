@font-face {
	font-family: 'icomoon';
	src:url('../fonts/fa/icomoon.eot?xjpork');
	src:url('../fonts/fa/icomoon.eot?#iefixxjpork') format('embedded-opentype'),
		url('../fonts/fa/icomoon.ttf?xjpork') format('truetype'),
		url('../fonts/fa/icomoon.woff?xjpork') format('woff'),
		url('../fonts/fa/icomoon.svg?xjpork#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-image:before {
	content: "\e600";
}
.icon-edit:before {
	content: "\e601";
}
.icon-paper-plane:before {
	content: "\e602";
}
.icon-share-square:before {
	content: "\e603";
}
